/*
resources:
- https://github.com/h5bp/main.css/blob/main/src/_print.css
*/

*, ::before, ::after {
    box-shadow: none !important;
    text-shadow: none !important;
}

html {
    --width:auto;
    --color-bg:#fff;
    --color-text:#000;
    /*font-size:12pt; ?? */
}

header, footer {
    display: none;
}

a:link:after {
    content: " (" attr(href) ") ";
    font-size:.9em;
}
a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
}

a:link {
    text-decoration: underline;
}